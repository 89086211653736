.loagin{
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loagin-content{
    width: 500px;
    height: 500px;
    padding-top: 100px;
}