.LivestudioEdit{
    width: 96%;
    height: 95%;
    background: white;
    overflow: auto;
}

.LivestudioEdit .headers{
    width: 97%;
    height: 50px;
    border-bottom: 1px solid #ccc;
    line-height: 70px;
    font-family: '微软雅黑 Bold', '微软雅黑';
    font-weight: 700;
    font-style: normal;
    color: #1E1E1E;
    margin: 0 auto;
    font-size: 18px;
}

.LivestudioEdit .main{
    width: 100%;
    height: 791px;
}

.LivestudioEdit .main .main-content{
    width: 100%;
    padding-top: 20px;
}