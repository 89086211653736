*
{
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
}
html,body{
    width: 100%;
    height: 100%;
}

#root{
    width: 100%;
    height: 100%;
    display: flex;
    background:rgba(242, 242, 242, 1);;
}
.root-left{
    width: 200px;
    height: 100%;
    background: rgb(8, 2, 39);
}
.root-right{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.root-right-header{
    width: 100%;
    height: 50px;
    background: white;
}
.root-right-main{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}