.Header{
    width: 100%;
    height: 100%;
    line-height: 50px;
    font-size: 24px;
    color: cornflowerblue;
}

.Header li:nth-child(1){
    height: 100%;
    float: left;
    margin-left: 20px;
}

.Header li:nth-child(2){
    /* width: 100px;
    height: 100%; */
    float: right;
}

.Header li:nth-child(2) span:nth-child(2){
    color: blue;
}