.custom-video-warpper {
    margin-bottom: 10px;
  }
  .custom-video-warpper .video-js {
    border: 1px solid #ccc;
    position: relative;
  }
  .custom-video-warpper .custom-video-dimensions.vjs-fluid {
    padding-top: 0;
  }
  .custom-video-warpper .video-js .vjs-big-play-button {
    border-radius: 2em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
