.Generateplaybackmask{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.playback{
    width: 500px;
    height: 500px;
    background: white;
}
.playback .header{
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #ccc;
    line-height: 44px;
}
.playback .header span:nth-child(1){
    margin-left: 20px;
    font-weight: bolder;
} 
.playback .header span:nth-child(2){
    display: inline-block;
    float: right;
    margin-right: 20px;
    font-weight: bolder;
}

.playback .main{
    width: 100%;
    height: 91.1%;
    overflow: auto;
}

.playback .main .main-content{
    width: 100%;
}


.playback .main .main-content li{
    width: 80%;
    height: 40px;
    line-height: 40px;
    margin: 20px auto;
}
.playback .main .main-content li span:nth-child(1){
    display: inline-block;
    width: 20%;
    margin-right: 10px;
}
.playback .main .main-content li input{
    width: 70%;
    height: 70%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 10px;
    margin-left: 10px;
}



.Generateplaybackmask .updata{
    width: 500px;
    height: 500px;
    background:white;
    background-size: 500px;
}

.Generateplaybackmask .updata .header{
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #ccc;
    line-height: 44px;
}
.Generateplaybackmask .updata .header span:nth-child(1){

    margin: 0 20px;
}
.Generateplaybackmask .updata .header span:nth-child(2){
    float: right;
    margin: 0 20px;
}
.Generateplaybackmask .updata .main{
    width: 100%;
    height: 91.1%;
    overflow: auto;
}

.Generateplaybackmask .updata .main .main-content{
    width: 100%;
}

.Generateplaybackmask .updata .main .main-content li{
    width: 100%;
    height: 46px;
    padding-left: 20px;
    margin-top: 30px;
}
.Generateplaybackmask .updata .main .main-content li input{
    margin-left: 20px;
    padding-left: 10px;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid rgb(226, 223, 223);
    box-sizing: border-box;
    outline: none;
}

.Generateplaybackmask .updata .main .main-content li span{
    display: inline-block;
    width: 70px;
    text-align: right;
}
.Generateplaybackmask .updata .main .main-content li .ant-select {
  width: 128px;
}
.Generateplaybackmask .updata .main .main-content li button{
    width: 30%;
    height: 60%;
    border: none;
    outline: none;
    background: rgb(27, 158, 114);
    color: white;
    font-weight: bold;
    margin-left: 54px;
}





.Generateplaybackmask .Upperandlower{
    width: 500px;
    height: 500px;
    background: white;
}


.Generateplaybackmask .Upperandlower .header{
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #ccc;
    line-height: 44px;
}


.Generateplaybackmask .Upperandlower .header span:nth-child(1){

    margin: 0 20px;
}
.Generateplaybackmask .Upperandlower .header span:nth-child(2){
    float: right;
    margin: 0 20px;
}

.Generateplaybackmask .Upperandlower .main{
    width: 100%;
    height: 91.1%;
    overflow: auto;
}


.Generateplaybackmask .Upperandlower .main li{
    width: 100%;
    margin-top: 40px;
    /* text-align: center; */
    
}
.Generateplaybackmask .Upperandlower .main p{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    
}



.Generateplaybackmask .Upperandlower .main p button{
    width: 30%;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    outline:none;
    border:none;
    float: left;
    margin-right: 10px;
}

.Generateplaybackmask .Upperandlower .main p button:nth-child(2){
    width: 30%;
    height: 40px;
    text-align: center;
    background: rgb(27, 158, 114);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    outline:none;
    border:none;
    float: left;
    margin-left: 10px;
}
.Generateplaybackmask .Upperandlower .main span{
   display: inline-block;
   width: 20%;
   font-weight: bold;
   height: 100%;
   text-align: right;
   padding-left: 20px;
    
}


.Generateplaybackmask .Upperandlower .main input{
    margin-left: 30px;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid rgb(230, 225, 225);
    outline: none;
    margin-right: 20px;
 }

 .Generateplaybackmask .Upperandlower .main img{
     max-width: 50px;
     max-height: 50px;
    border-radius: 5px;;
 }