.Broadcastinglist{
    width: 96%;
    height: 95%;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Broadcastinglist-header{
    width: 97%;
    height: 130px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.Broadcastinglist-header-title{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ccc;
    line-height: 70px;
    font-family: '微软雅黑 Bold', '微软雅黑';
    font-weight: 700;
    font-style: normal;
    color: #1E1E1E;
}
.Broadcastinglist-header-main{
    flex: 1;
    display: flex;
    align-items: center;
}
.Broadcastinglist-main{
    height: 712px;
    overflow: auto;
}

.Broadcastinglist .ant-upload{
    height: 20px !important;
}