.pushflow{
    width: 96%;
    height: 95%;
    background: white;
    overflow: auto;
}

.pushflow .headers{
    width: 97%;
    height: 50px;
    border-bottom: 1px solid #ccc;
    line-height: 70px;
    font-family: '微软雅黑 Bold', '微软雅黑';
    font-weight: 700;
    font-style: normal;
    color: #1E1E1E;
    margin: 0 auto;
}

.pushflow .main{
    padding-top: 20px;
    padding-left: 20px;
}

.pushflow .main div{
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin: 20px auto;
}

.pushflow .main div span{
    display: block;
    width: 100px;
    float: left;
    text-align: right;
}

.pushflow .main div input{
   width: 596px;
   height: 100%;
   margin: 0 10px;
   border: 1px solid #ccc;
   padding-left: 10px;
}

.pushflow .main div button{
    height: 100%;
    width: 80px;
    border-radius: 5px;
    background-color: rgba(45, 140, 240, 1);
    color: white;
    border: none;
}