.watch{
    width: 96%;
    height: 95%;
    background: white;
}

.watch .headers{
    width: 97%;
    height: 50px;
    border-bottom: 1px solid #ccc;
    line-height: 70px;
    font-family: '微软雅黑 Bold', '微软雅黑';
    font-weight: 700;
    font-style: normal;
    color: #1E1E1E;
    margin: 0 auto;
}


.video-react-controls-enabled{
    margin: 100px auto;
}

.video-react-big-play-button
{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}