.chatpage{
    width: 96%;
    height: 95%;
    display: flex;
}
.main-loading{
    display: flex;
    align-items: center;
    justify-content: center;
}
.chatpage .frindList{
    width: 250px;
    display: flex;
    flex-direction: column;
}

.chatpage .frindList .search-box{
    height: 44px;
    width: 100%;
    display: flex;
    background: white;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.chatpage .frindList .search-box input{
    height: 70%;
    width: 80%;
    margin-left: 5px;
    outline: none;
    border: none;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.chatpage .frindList .search-box button{
    height: 69%;
    border: none;
    flex: 1;
    margin: 0 5px;
    outline: none;
}

.chatpage .frindList .friends{
    flex: 1;
    overflow: auto;
}

.chatpage .frindList .friends .frients-item{
    width: 100%;
    height: 140px;
    border-bottom: 1px solid #ccc;
    margin-top: 20px;
    position: relative;
    display: flex;
}
.chatpage .frindList .friends .frients-item .Tips{
    display: inline-block;
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    background: red;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    color: white;
}
.frindList .frients-left{
    width: 25%;
    height: 60px;
}
.frindList .frients-left img{
    width: 100%;
    height: 100%;
}
.frindList .frients-right{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.frindList .frients-right h2{
   flex: 1;
   overflow: hidden;
   text-overflow:ellipsis;
   white-space: nowrap;
   display: flex;
   justify-content: center;
   align-items: center;
}
.frindList .frients-right div{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.frindList .frients-right div li:nth-child(1){
    flex: 1;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    font-weight: bolder;
}
.frindList .frients-right div li:nth-child(2){
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-size: 12px;
    color: #ccc;
}
.chatpage .chartlist{
    flex: 7;
    display: flex;
    flex-direction: column;
}
.chatpage .chartlist .header{
    width: 100%;
    height: 44px;
    background: white;
    border-bottom: 1px solid #ccc;
    display: flex;
    
}

.chatpage .chartlist  .header span:nth-child(1){
    flex: 3.8;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
}
.chatpage .chartlist  .header span:nth-child(2){
    flex: 7;;
    line-height: 32px;
    font-size: 18px;
    padding-left: 30px;
}
.chatpage .chartlist .main{
    width: 100%;
    height: 748px;
    overflow-y: auto;
    background: white;
    padding-bottom: 30px;
}

.chatpage .chartlist .main .main-content{
    width: 100%;
}
.chatpage .main .main-content .leftnews{
    margin: 20px 10px;
    display: flex;
    flex-direction: row;
}
.leftnews  .img-box
{
  border-radius: 50%;
  margin-right: 20px;
}
.leftnews  .img-box img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}


.leftnews .news-box{
    border-bottom: 1px solid #F2F2F2;
    max-width: 70%;
    background: white;
    padding: 10px;
    border-radius: 5px;    
 }
.leftnews .news-box p{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:flex-end;
    flex-direction: column;
    align-items: center;
    word-break: break-all;
    font-size: 14px;
    color: #5D5D5D;
    position: relative;
  }
  .leftnews .news-box p .time{
    font-size: 12px;
    margin-top: 5px;
    color: #ccc;
  }

.leftnews .news-box p .triangle{
    width:0;

    height:0;

    overflow:hidden;

    border:7px solid transparent;

    border-right-color:white;

    position: absolute;
    left: -22px;
    top: 20%;
    margin-top: -3.5px;;
  }

.chatpage .main .main-content .rightnews{
    margin: 20px 10px;
    display: flex;
    flex-direction: row-reverse;
}
.rightnews .img-box{
    background: blue;
    width: 40px;
    height: 40px;
    margin-left: 20px;
    border-radius: 50%;;
}
.rightnews .img-box img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.rightnews .news-box{
    border-bottom: 1px solid #F2F2F2;
    max-width: 70%;
    background: white;
    padding: 10px;
    border-radius: 5px;
}
.rightnews .news-box p{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    word-break: break-all;
    font-size: 14px;
    color: #5D5D5D;
    position: relative;
}
.rightnews .news-box p .triangle{
    width:0;

    height:0;

    overflow:hidden;

    border:7px solid transparent;

    border-left-color:white;

    position: absolute;
    right: -22px;
    top: 20%;
    margin-top: -3.5px;;
}
.chatpage .chartlist .footer{
    width: 100%;
    height: 280px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;

}

.chatpage .chartlist .footer .footer-header{
    width: 100%;
    height: 50px;
    background: white;
    padding-top: 5px;
}

.chatpage .chartlist .footer .footer-main{
    flex: 1;
    background: white;
    outline: none;
    padding:10px;
    overflow: auto;
    word-break: break-all;
}

.chatpage .chartlist .footer .footer-footer{
    width: 100%;
    height: 60px;
    text-align: right;
    position: relative;
    background: white;
}

.chatpage .chartlist .footer .footer-footer button{
    width: 100px;
    height: 40px;
    background: white;
    border: 1px solid #ccc;
    position: absolute;
    right: 20px;
}
.chatpage .chartlist .footer .footer-header .ant-upload{
    width: 40px !important;
    height: 0px !important;
    margin-left: 20px;
}
/* .chatpage .chartlist .footer div:nth-child(1){
    width: 100%;
}
.chatpage .chartlist .footer div:nth-child(1) input{
    width: 85%;
    border: 1px solid #ccc;
    border-radius: 50px;
    height: 40px;
    margin: 5px auto;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
    padding-left: 10px;
}
.chatpage .chartlist .footer div:nth-child(1) .anticon{
    font-size: 28px;
}

.chatpage .chartlist .footer .choice{
    width: 100%;
    height: 100px;
    border-top: 1px solid #ccc;
    transition: all 0.5s;
    display: flex;
    align-items: center;
}

.chatpage .chartlist .footer .choice .ant-upload{
    height: 50px;
} */